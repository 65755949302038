.navbar_home:active {
    background-color: lightgray !important;
}

.navbar_top_100:active {
    background-color: lightgray !important;
}

.navbar_subscribe:active {
    background-color: lightgray !important;
}

.navbar_signout:active {
    background-color: lightgray !important;
}

.navbar_account_dropdown:active {
    background-color: lightgray !important;
    color: black !important;
}

.navbar_discover_button {
    font-size: 10px !important;
}

.navbar_account_dropdown:hover {
    color: black !important;
    box-shadow: none;
    background-color: #eeeeee;
}

.navbar_option:hover {
    color: black;
    box-shadow: none;
    background-color: #e6e6e6;
}

.layout_navbar {
    height: 8vh !important;
}

.navbar_web_logo_txt {
    height: 40px;
    width: 200px;
}

.navbar_account_dropdown {
    height: 50px;
    width: 120px;
}

.navbar_web_logo_text {
    font-size: 26px;
}