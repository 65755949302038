.container_discover_button {
    font-size: 10px !important;
}

.article_pointer {
    cursor: pointer;
}

.imageContainer {
    height: 150px;
}

@media screen and (min-width: 450px) and (max-width: 767px) {
    .imageContainer {
        height: 400px;
    }
}

@media screen and (min-width: 577px) {
    .shareButton {
        max-height: 30px !important;
    }
}

@media screen and (min-width: 768px) {
    .article_pointer {
        max-height: 150px !important;
    }
}

@media screen and (max-width: 767px) {
    .article_pointer {
        max-height: 1000px !important;
    }
}