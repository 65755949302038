.loading_screen {
    height: 650px;
}

.loading_video_ratio {
    width: 75vw !important;
    height: 30vh !important;
}

.loading_long_screen {
    height: 70vh !important;
}