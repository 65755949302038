/*Will have to do until we can figure out how to make basic width and height classes responsive*/
@media screen and (max-width: 575px) {
    .w-xs-25 {
        width: 25% !important;
    }
    .w-xs-50 {
        width: 50% !important;
    }
    .w-xs-75 {
        width: 75% !important;
    }
    .w-xs-80 {
        width: 80% !important;
    }
    .w-xs-100 {
        width: 100% !important;
    }
}
@media screen and (max-width: 767px) and (min-width: 576px) {
    .w-sm-25 {
        width: 25% !important;
    }
    .w-sm-50 {
        width: 50% !important;
    }
    .w-sm-75 {
        width: 75% !important;
    }
    .w-sm-80 {
        width: 80% !important;
    }
    .w-sm-100 {
        width: 100% !important;
    }
}
@media screen and (max-width: 991px) and (min-width: 768px) {
    .w-md-25 {
        width: 25% !important;
    }
    .w-md-50 {
        width: 50% !important;
    }
    .w-md-75 {
        width: 75% !important;
    }
    .w-md-100 {
        width: 100% !important;
    }
}
@media screen and (max-width: 1199px) and (min-width: 992px) {
    .w-lg-25 {
        width: 25% !important;
    }
    .w-lg-50 {
        width: 50% !important;
    }
    .w-lg-75 {
        width: 75% !important;
    }
    .w-lg-100 {
        width: 100% !important;
    }
}
@media screen and (max-width: 1399px) and (min-width: 1200px) {
    .w-xl-25 {
        width: 25% !important;
    }
    .w-xl-50 {
        width: 50% !important;
    }
    .w-xl-75 {
        width: 75% !important;
    }
    .w-xl-100 {
        width: 100% !important;
    }
}
@media screen and (min-width: 1400px) {
    .w-xxl-25 {
        width: 25% !important;
    }
    .w-xxl-50 {
        width: 50% !important;
    }
    .w-xxl-75 {
        width: 75% !important;
    }
    .w-xxl-100 {
        width: 100% !important;
    }
}