.home_countdown_img_pulse_wrapper {
  height: 550px;
}

.home_search_mt_n {
  margin-top: -425px !important;
}

.home_search_query_input {
  height: 2.8rem;
  outline: none;
}

.home_mobile_search_query_input {
  min-width: 90% !important;
  outline: none;
  height: 2.8rem;
}

.home_mobile_discover_button {
  min-width: 90% !important;
}

@media (max-width: 767px) {
  .home_countdown_img_pulse_wrapper {
    height: 600px;
  }
}

@media screen and (max-height: 800px) {
  .home_recent_div {
    max-height: 600px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .home_search_mt_n_mobile {
    margin-top: -550px !important;
  }
}
