.dashboard_sidebar {
    background-image: url("../assets/cloud.jpeg");
    background-size: 85vh;
}

.dashboard_selected {
    background-color: black !important;
    color: white !important;
}

.dashboard_unselected {
    color: black !important;
}

.dashboard_unselected:hover {
    color: white !important;
}

.dashboard_card {
    height: 480px !important;
}